var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"document"},[_c('el-form',{ref:"form",attrs:{"model":_vm.formData,"rules":_vm.rules}},[(_vm.isEndorsement)?[_c('el-row',{staticClass:"row-bg",attrs:{"gutter":20,"justify":"space-around"}},[_c('v-row-col',[_c('div',{staticClass:"form-item"},[_c('span',[_vm._v("Cover Note")]),_c('el-form-item',{attrs:{"prop":"covernote_document_file_id"}},[_c('file-container-card',{attrs:{"edit":true,"card":"","fileData":{
                  url: _vm.formData.covernote_document_file_url,
                  client_name: _vm.formData.covernote_document_file_client_name
                },"otherData":{
                  idKey: 'covernote_document_id',
                  urlKey: 'covernote_document_file_url',
                  client_name:'covernote_document_file_client_name'
                }},on:{"delete":_vm.handlerDeleteFile,"uploadSuccess":_vm.handlerUploadSuccess}})],1)],1)])],1),_c('el-row',{staticClass:"row-bg",attrs:{"gutter":20,"justify":"space-around"}},[_c('v-row-col',[_c('div',{staticClass:"form-item "},[_c('span',[_vm._v("Cover Note Number")]),_c('el-form-item',{ref:"covernote_no",attrs:{"prop":"covernote_no"}},[_c('v-input',{attrs:{"is-upper":""},on:{"blur":_vm.handlerCheckCovernote},model:{value:(_vm.formData.covernote_no),callback:function ($$v) {_vm.$set(_vm.formData, "covernote_no", $$v)},expression:"formData.covernote_no"}})],1)],1)])],1),_c('el-row',{staticClass:"row-bg",attrs:{"gutter":20,"justify":"space-around"}},[_c('v-row-col',[_c('div',{staticClass:"form-item "},[_c('span',[_vm._v("Policy")]),_c('el-form-item',{attrs:{"prop":"policy_document_file_id"}},[_c('file-container-card',{attrs:{"edit":true,"fileData":{
                  url: _vm.formData.policy_document_file_url,
                  client_name: _vm.formData.policy_document_client_name
                },"otherData":{
                  idKey: 'policy_document_id',
                  urlKey: 'policy_document_file_url',
                  client_name:'policy_document_client_name'
                }},on:{"delete":_vm.handlerDeleteFile,"uploadSuccess":_vm.handlerUploadSuccess}})],1)],1)])],1),_c('el-row',{staticClass:"row-bg",attrs:{"gutter":20,"justify":"space-around"}},[_c('v-row-col',[_c('div',{staticClass:"form-item "},[_c('span',[_vm._v("Policy Number ")]),_c('el-form-item',{attrs:{"prop":"policy_no"}},[_c('v-input',{on:{"change":_vm.handlerPolicyNumber},model:{value:(_vm.formData.policy_no),callback:function ($$v) {_vm.$set(_vm.formData, "policy_no", $$v)},expression:"formData.policy_no"}})],1)],1)])],1)]:_vm._e(),_c('el-row',{staticClass:"row-bg",attrs:{"gutter":20}},[(!_vm.isCompany)?_c('v-row-col',[_c('div',{staticClass:"form-item "},[_c('span',[_vm._v("HKID Card / Passport ")]),_c('el-form-item',{ref:"hkid_file_id",attrs:{"rules":_vm.rules.upload,"prop":"hkid_file_id"}},[_c('file-container-card',{attrs:{"edit":true,"fileData":_vm.formData.hkid_file,"otherData":{
                idKey: 'hkid_file_id',
                urlKey: 'hkid_file_url',
                name:'hkid_file'
              }},on:{"delete":_vm.handlerDeleteFile,"uploadSuccess":_vm.handlerUploadSuccess}})],1)],1)]):[_c('v-row-col',[_c('div',{staticClass:"form-item "},[_c('span',[_vm._v("CI")]),_c('el-form-item',{ref:"ci_file_id",attrs:{"prop":"ci_file_id","rules":_vm.rules.upload}},[_c('file-container-card',{attrs:{"edit":true,"fileData":_vm.formData.ci_file,"otherData":{
                  idKey: 'ci_file_id',
                  urlKey: 'ci_file_url',
                  name:'ci_file'
                }},on:{"delete":_vm.handlerDeleteFile,"uploadSuccess":_vm.handlerUploadSuccess}})],1)],1)]),_c('v-row-col',[_c('div',{staticClass:"form-item"},[_c('span',[_vm._v("BR")]),_c('el-form-item',{ref:"br_file_id",attrs:{"prop":"br_file_id"}},[_c('file-container-card',{attrs:{"edit":true,"fileData":_vm.formData.br_file,"otherData":{
                  idKey: 'br_file_id',
                  urlKey: 'br_file_url',
                  name:'br_file'
                }},on:{"delete":_vm.handlerDeleteFile,"uploadSuccess":_vm.handlerUploadSuccess}})],1)],1)])],_c('v-row-col',[_c('div',{staticClass:"form-item "},[_c('span',[_vm._v("Vehicle Registration Document ")]),_c('el-form-item',{attrs:{"prop":"vrd_file_id"}},[_c('file-container-card',{attrs:{"edit":true,"fileData":_vm.formData.vrd_file,"otherData":{
                idKey: 'vrd_file_id',
                urlKey: 'vrd_file_url',
                name:'vrd_file'
              }},on:{"delete":_vm.handlerDeleteFile,"uploadSuccess":_vm.handlerUploadSuccess}})],1)],1)]),(_vm.ncd!=0)?_c('v-row-col',[_c('div',{staticClass:"form-item "},[_c('span',[_vm._v("NCB proof (e.g. Renewal Notice) ")]),_c('el-form-item',{ref:"ncb_file_id",attrs:{"prop":"ncb_file_id"}},[_c('file-container-card',{attrs:{"edit":true,"fileData":_vm.formData.ncb_file,"otherData":{
                idKey: 'ncb_file_id',
                urlKey: 'ncb_file_url',
                name:'ncb_file'
              }},on:{"delete":_vm.handlerDeleteFile,"uploadSuccess":_vm.handlerUploadSuccess}})],1)],1)]):_vm._e(),_c('v-row-col',[_c('div',{staticClass:"form-item "},[_c('span',[_vm._v("Proof of Address（optional）")]),_c('el-form-item',{ref:"proof_of_address_file_id",attrs:{"prop":"proof_of_address_file_id"}},[_c('file-container-card',{attrs:{"edit":true,"fileData":_vm.formData.proof_of_address_file,"otherData":{
                idKey: 'proof_of_address_file_id',
                urlKey: 'proof_of_address_file_url',
                name:'proof_of_address_file'
              }},on:{"delete":_vm.handlerDeleteFile,"uploadSuccess":_vm.handlerUploadSuccess}})],1)],1)])],2),(_vm.showMotor)?_vm._l((_vm.formData.driver_document),function(driver,d_index){return _c('section',{key:'driver_'+d_index,staticClass:"dirver-line"},[_c('div',{staticClass:"flex-col text-left driver-text-title"},[_c('span',[_vm._v("Driver "+_vm._s(d_index+1))])]),_c('section',{staticClass:"flex-wrap"},[(_vm.isOneOfDriver&&d_index!=0||_vm.isCompany||!_vm.isOneOfDriver)?_c('div',{staticClass:"form-item form-half"},[_c('div',{staticClass:"flex-col text-left"},[_c('span',[_vm._v("HKID")])]),_c('el-form-item',{ref:("driver_document." + d_index + ".id_file_id"),refInFor:true,attrs:{"prop":("driver_document." + d_index + ".id_file_id"),"rules":_vm.rules.upload}},[_c('file-container-card',{attrs:{"edit":true,"fileData":driver.id_file,"otherData":{
                  idKey: 'id_file_id',
                  urlKey: 'id_file_url',
                  name:'id_file',
                  index:d_index,
                  type:'driver'
                }},on:{"delete":_vm.handlerDeleteFile,"uploadSuccess":_vm.handlerUploadSuccess}})],1)],1):_vm._e(),_c('div',{staticClass:"form-item form-half"},[_c('div',{staticClass:"flex-col text-left"},[_c('span',[_vm._v("Driving Licence")])]),_c('el-form-item',{ref:("driver_document." + d_index + ".driving_licence_file_id"),refInFor:true,attrs:{"rules":_vm.rules.upload,"prop":("driver_document." + d_index + ".driving_licence_file_id")}},[_c('file-container-card',{attrs:{"edit":true,"fileData":driver.driving_licence_file,"otherData":{
                  idKey: 'driving_licence_file_id',
                  urlKey: 'driving_licence_file_url',
                  name:'driving_licence_file',
                  index:d_index,
                  type:'driver'
                }},on:{"delete":_vm.handlerDeleteFile,"uploadSuccess":_vm.handlerUploadSuccess}})],1)],1),(_vm.check70Years(driver.birth))?_c('div',{staticClass:"form-item form-half"},[_c('div',{staticClass:"flex-col text-left"},[_c('span',[_vm._v("Medical Examination Certificate")])]),_c('el-form-item',{ref:("driver_document." + d_index + ".mec_file_id"),refInFor:true,attrs:{"rules":_vm.rules.upload,"prop":("driver_document." + d_index + ".mec_file_id")}},[_c('file-container-card',{attrs:{"edit":true,"fileData":driver.mec_file,"otherData":{
                  idKey: 'mec_file_id',
                  urlKey: 'mec_file_url',
                  name:'mec_file',
                  index:d_index,
                  type:'driver'
                }},on:{"delete":_vm.handlerDeleteFile,"uploadSuccess":_vm.handlerUploadSuccess}})],1)],1):_vm._e(),(driver.has_accident==2)?_vm._l((driver.accidents_file),function(item,a_index){return _c('div',{key:'accidents_file_'+a_index,staticClass:"form-item form-half"},[_c('div',{staticClass:"flex-col text-left"},[_c('span',[_vm._v("Police Statement "+_vm._s(a_index+1)+" (optional）")])]),_c('el-form-item',{ref:("driver_document." + d_index + ".accidents_file_id"),refInFor:true,attrs:{"prop":("driver_document." + d_index + ".accidents_file_id")}},[_c('file-container-card',{attrs:{"edit":true,"fileData":item,"otherData":{
                    idKey: 'accidents_file_id',
                    name:'accidents_file',
                    index:d_index,
                    a_index:a_index,
                    type:'driver'
                  }},on:{"delete":_vm.handlerDeleteFile,"uploadSuccess":_vm.handlerUploadSuccess}})],1)],1)}):_vm._e(),(driver.is_dangerous_driving==1)?_c('div',{staticClass:"form-item form-half"},[_c('div',{staticClass:"flex-col text-left"},[_c('span',[_vm._v("Court Summons (optional）")])]),_c('el-form-item',{ref:("driver_document." + d_index + ".driving_licence_file_id"),refInFor:true,attrs:{"prop":("driver_document." + d_index + ".driving_licence_file_id")}},[_c('file-container-card',{attrs:{"edit":true,"fileData":driver.dangerous_driving_file,"otherData":{
                  idKey: 'dangerous_driving_file_id',
                  urlKey: 'dangerous_driving_file_url',
                  name:'dangerous_driving_file',
                  index:d_index,
                  type:'driver'
                }},on:{"delete":_vm.handlerDeleteFile,"uploadSuccess":_vm.handlerUploadSuccess}})],1)],1):_vm._e()],2)])}):_vm._e(),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex-1"},[[_c('section',[(_vm.showMotor)?_c('div',{staticClass:"hr-inline"}):_vm._e(),_c('div',{staticClass:"mg-b-20"},[_c('div',{staticClass:"form-item  document-file "},[_c('div',{staticClass:"flex mg-b-20"},[_c('p',{staticClass:"fw-700 fs-16"},[_vm._v("Other Document")]),_c('p',{staticClass:"hand primary mg-l-10 fs-14 fw-400",on:{"click":_vm.handleAddOther}},[_vm._v("Add Document")])]),_c('div',{staticClass:"theme-second-blue  mg-t-20"},_vm._l((_vm.formData.other_documents),function(item,index){return _c('div',{key:index,staticClass:"flex-sb-center other-upload-box"},[_c('p',{staticClass:"other-file-name hand",on:{"click":function($event){return _vm.openFile(item.url)}}},[(item.name)?_c('font-awesome-icon',{staticClass:"mg-r-8 primary",attrs:{"icon":"paperclip"}}):_vm._e(),_c('span',{staticClass:"primary fs-14"},[_vm._v(_vm._s(item.name||'Please Upload'))])],1),_c('span',[_c('span',{staticClass:"hand",on:{"click":function($event){return _vm.handleUploadOther(item,index)}}},[_vm._v(_vm._s(item.url?'Edit':'Upload'))]),_c('span',{staticClass:"hand",on:{"click":function($event){return _vm.handleDelUploadOther(index)}}},[_vm._v("Delete")])])])}),0)])]),_c('div',{staticClass:"flex-sb"},[_c('div',{staticClass:"form-item"},[_c('span',[_vm._v("Full Set Doc. Received?")]),_c('div',{staticClass:"car-type-radio"},[_c('el-form-item',[_c('v-radio-group',{attrs:{"border":true,"list":_vm.yesNoOptions},model:{value:(_vm.formData.documents_received),callback:function ($$v) {_vm.$set(_vm.formData, "documents_received", $$v)},expression:"formData.documents_received"}})],1)],1)])])])]],2)])],2),_c('upload-other-document',{attrs:{"form":_vm.other_upload},on:{"confirm":_vm.handleUploadOtherConfirm},model:{value:(_vm.showUpload),callback:function ($$v) {_vm.showUpload=$$v},expression:"showUpload"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }